import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "integrate-a-c-service"
    }}>{`Integrate a C# service`}</h2>
    <p>{`To extract the End-User-Permission token from the request, in the following we use a Middleware to extract
the token and store it in the current HttpContext of the request.`}</p>
    <p>{`Then, when needed, a Verifier is invoked, parsing and checking the token.`}</p>
    <p>{`In the following you can have a look at a sample middleware implementation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using ...

namespace YanchWare.Ocelot.Samples;

public class JwtExtractorMiddleware
{
    private readonly RequestDelegate _nextRequest;
    private readonly ILogger<JwtExtractorMiddleware> _logger;

    public JwtExtractorMiddleware(
        ILogger<JwtExtractorMiddleware> logger,
        RequestDelegate nextRequest)
    {
        _nextRequest = nextRequest;
        _logger = logger;
    }

    public async Task Invoke(HttpContext context)
    {
        if (context == null)
        {
            _logger.LogError($"{nameof(context)} is null.");
            // Context is null so no response can be sent back to the client
            return;
        }

        // Naive implementation just to give an idea on how to allow access to public endpoints
        if (context.Request.Path.Equals("/health"))
        {
            await _nextRequest(context);
            return;
        }

        if (!context.Request.Headers.TryGetValue(HttpJwtHeaderName, out var jwtHeaderValues))
        {
            var message = $"Request has been rejected because is missing JWT header '{HttpJwtHeaderName}'";
            _logger.LogWarning(message);

            ReturnErrorResponse(
                context.Response,
                HttpStatusCode.Unauthorized,
                SecurityErrorReasonCodes.IllegalJwt.ToString(),
                message);

            return;
        }

        if (jwtHeaderValues.Count != 1)
        {
            var message =
                $"Request has been rejected because JWT header '{HttpJwtHeaderName}' has {jwtHeaderValues.Count} values: " +
                $"{string.Join(',', jwtHeaderValues)}";
            _logger.LogWarning(message);
            ReturnErrorResponse(
                context.Response,
                HttpStatusCode.Unauthorized,
                SecurityErrorReasonCodes.IllegalJwt.ToString(),
                message);
            return;
        }

        try
        {
            // Add the token raw value to the HttpContext for later evaluation
            context.Items.Add(
                HttpContextSecurityClaimsKey,
                jwtHeaderValues[0]);
            await _nextRequest(context);
        }
        catch (BadRequestException ex)
        {
            ReturnErrorResponse(
                context.Response,
                HttpStatusCode.BadRequest,
                ex.ReasonCode,
                ex.Message);
        }
        catch (InternalErrorException ex)
        {
            ReturnErrorResponse(
                context.Response,
                HttpStatusCode.InternalServerError,
                ex.ReasonCode,
                ex.Message);
        }
        catch (SecurityException ex)
        {
            ReturnErrorResponse(
                context.Response,
                HttpStatusCode.Unauthorized,
                ex.ReasonCode,
                ex.Message);
        }
        catch (Exception ex)
        {
            _logger.LogError(ex, "Error in chained middlewares");
            ReturnErrorResponse(
                context.Response,
                HttpStatusCode.InternalServerError,
                InternalErrorReasonCodes.GenericError.ToString(),
                ex.Message);
        }
    }

    private void ReturnErrorResponse(
        HttpResponse response,
        HttpStatusCode httpStatusCode,
        string reasonCode,
        string message)
    {
        _logger.LogWarning($"Failure in controller. Reason code: {reasonCode} message: {message}");
        if (!response.HasStarted)
        {
            response.StatusCode = (int) httpStatusCode;
            response.WriteAsync(Serialize(new
            {
                reasonCode,
                message
            }));
        }
        else
        {
            _logger.LogWarning("Response is already started so no error could be send at this time to the client");
        }
    }
}
`}</code></pre>
    <p>{`The complete samples are available on our `}<a parentName="p" {...{
        "href": "https://github.com/YanchWare/ocelot-samples"
      }}>{`github repo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      